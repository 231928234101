import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  FirebaseStateType,
  TopicResponseType,
  PushNotificationDataType,
  ActiveDeviceCountRequestType,
} from 'app/types';

const initialState: FirebaseStateType = {
  topicData: null,
  topicDataLoading: false,
  topicDataError: null,

  sendingPushNotification: false,
  sendingPushNotificationError: null,

  activeDeviceCount: null,
  activeDeviceCountLoading: false,
  activeDeviceCountError: null,
};

const handleGetTopics = (state: FirebaseStateType) => {
  state.topicDataLoading = true;
  state.topicDataError = null;
};

const handleGetTopicsSuccess = (state: FirebaseStateType, action: PayloadAction<TopicResponseType>) => {
  state.topicData = action.payload;
  state.topicDataLoading = false;
  state.topicDataError = null;
};

const handleGetTopicsFailure = (state: FirebaseStateType, action: PayloadAction<any>) => {
  state.topicData = null;
  state.topicDataLoading = false;
  state.topicDataError = action.payload;
};

const handleGetActiveDeviceCount = (state: FirebaseStateType) => {
  state.activeDeviceCountLoading = true;
  state.activeDeviceCountError = null;
};

const handleGetActiveDeviceCountSuccess = (state: FirebaseStateType, action: PayloadAction<number>) => {
  state.activeDeviceCount = action.payload;
  state.activeDeviceCountLoading = false;
  state.activeDeviceCountError = null;
};

const handleGetActiveDeviceCountFailure = (state: FirebaseStateType, action: PayloadAction<any>) => {
  state.activeDeviceCount = null;
  state.activeDeviceCountLoading = false;
  state.activeDeviceCountError = action.payload;
};

const handleSendPushNotification = (state: FirebaseStateType) => {
  state.sendingPushNotification = true;
  state.sendingPushNotificationError = null;
};

const handleSendPushNotificationSuccess = (state: FirebaseStateType) => {
  state.sendingPushNotification = false;
  state.sendingPushNotificationError = null;
};

const handleSendPushNotificationError = (state: FirebaseStateType, action: PayloadAction<any>) => {
  state.sendingPushNotification = false;
  state.sendingPushNotificationError = action.payload;
};

const cleanPushNotificationError = (state: FirebaseStateType, action: PayloadAction<any>) => {
  state.sendingPushNotificationError = null;
};

const cleanActiveDeviceCount = (state: FirebaseStateType, action: PayloadAction<any>) => {
  state.activeDeviceCount = null;
};

const firebaseSlice = createSlice({
  name: '@@firebase',
  initialState,
  reducers: {
    handleGetTopics(state, action: PayloadAction<null>) {
      return handleGetTopics(state);
    },
    handleGetTopicsSuccess(state, action: PayloadAction<TopicResponseType>) {
      return handleGetTopicsSuccess(state, action);
    },
    handleGetTopicsFailure(state, action: PayloadAction<null>) {
      return handleGetTopicsFailure(state, action);
    },
    handleGetActiveDeviceCount(state, action: PayloadAction<ActiveDeviceCountRequestType>) {
      return handleGetActiveDeviceCount(state);
    },
    handleGetActiveDeviceCountSuccess(state, action: PayloadAction<number>) {
      return handleGetActiveDeviceCountSuccess(state, action);
    },
    handleGetActiveDeviceCountFailure(state, action: PayloadAction<null>) {
      return handleGetActiveDeviceCountFailure(state, action);
    },
    handleSendPushNotification(state, action: PayloadAction<PushNotificationDataType>) {
      return handleSendPushNotification(state);
    },
    handleSendPushNotificationSuccess(state, action: PayloadAction<any>) {
      return handleSendPushNotificationSuccess(state);
    },
    handleSendPushNotificationError(state, action: PayloadAction<any>) {
      return handleSendPushNotificationError(state, action);
    },
    cleanPushNotificationError(state, action: PayloadAction<any>) {
      return cleanPushNotificationError(state, action);
    },
    cleanActiveDeviceCount(state, action: PayloadAction<null>) {
      return cleanActiveDeviceCount(state, action);
    },
  },
});

export const { name, actions, reducer } = firebaseSlice;
