import { POST } from './Axios.service';
import { LoginActionType } from 'app/types';

const baseURL = `${process.env.REACT_APP_API_BASE_URL}/api`;

const urlFactory = {
  login: `${baseURL}/login`,
};

const handleLoginService = async (loginData: LoginActionType) => {
  try {
    return POST(urlFactory.login, loginData);
  } catch (error) {
    throw error;
  }
};

export { handleLoginService };
