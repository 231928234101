import { GET, POST } from './Axios.service';
import { PushNotificationDataType } from 'app/types';

const baseURL = `${process.env.REACT_APP_API_BASE_URL}/api`;

const urlFactory = {
  getTopics: `${baseURL}/getTopics`,
  getActiveDeviceCount: `${baseURL}/getActiveDevicesCount`,
  sendPushNotification: `${baseURL}/sendPushNotification`,
};

const handleGetTopics = async () => {
  try {
    return GET(urlFactory.getTopics, null);
  } catch (error) {
    throw error;
  }
};

const getActiveDeviceCount = async (selectedApp: string, selectedAppTopic: string, allTopics: boolean) => {
  try {
    return GET(urlFactory.getActiveDeviceCount, { selectedApp, selectedAppTopic, allTopics: allTopics || '' });
  } catch (error) {
    throw error;
  }
};

const handleSendPushNotification = async (pushNotificationData: PushNotificationDataType) => {
  try {
    return POST(urlFactory.sendPushNotification, pushNotificationData);
  } catch (error) {
    throw error;
  }
};

export { handleGetTopics, handleSendPushNotification, getActiveDeviceCount };
