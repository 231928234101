import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { Header, ProtectedRoute } from 'app/components';
import { LoginPage, NotificationPage } from 'app/pages';

const App: React.FC<any> = () => {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <NotificationPage />
            </ProtectedRoute>
          }
        />
        <Route path="/login" element={<LoginPage />} />
        <Route path="*" element={<p>There's nothing here: 404!</p>} />
      </Routes>
    </div>
  );
};

export default App;
