import React, { useEffect, useMemo, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Snackbar,
  Alert,
  CircularProgress,
} from '@mui/material';

import { InputField, SelectField, Preview } from 'app/components';
import { usePrevious } from 'app/hooks/customHooks';
import { actions as firebaseActions } from 'app/store/slices/Firebase.slice';
import { RootState } from 'app/store/Store';

import './NotificationPage.css';

const appData = [
  {
    label: 'Hänt - Development',
    value: 'hant-dev',
  },
  {
    label: 'Svenskdam - Development',
    value: 'svenskdam-dev',
  },
];

if (process.env.REACT_APP_ENV !== 'DEV') {
  appData.unshift({
    label: 'Hänt - Production',
    value: 'hant-prod',
  });
  appData.unshift({
    label: 'Svenskdam - Production',
    value: 'svenskdam-prod',
  });
}

const NotificationPage = () => {
  const dispatch = useDispatch();

  const topicData = useSelector((state: RootState) => state.firebase.topicData);
  const activeDeviceCount = useSelector((state: RootState) => state.firebase.activeDeviceCount);
  const sendingPushNotification = useSelector((state: RootState) => state.firebase.sendingPushNotification);
  const sendingPushNotificationError = useSelector((state: RootState) => state.firebase.sendingPushNotificationError);
  const prevSendingPushNotification = usePrevious(sendingPushNotification);

  const [selectedApp, setSelectedApp] = useState('');
  const [selectedAppTopic, setSelectedAppTopic] = useState('');
  const [selectedAppAllTopicsEnabled, setSelectedAppAllTopicsEnabled] = useState(false);
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [formError, setFormError] = useState<boolean>(false);

  const titleRef = useRef<any>(null);
  const bodyRef = useRef<any>(null);
  const articleURLRef = useRef<any>(null);

  const topicSelectionData = useMemo(() => {
    if (!selectedApp || !topicData) {
      return null;
    }

    const serverTopics = topicData[selectedApp]?.map(topic => ({
      label: topic,
      value: topic,
      allTopics: false,
    }));

    if (!serverTopics.find(t => t.value === 'alla'))
      serverTopics.push({
        label: 'alla',
        value: 'alla',
        allTopics: true,
      });

    return serverTopics;
  }, [selectedApp, topicData]);

  const handleClear = useCallback(() => {
    titleRef.current.value = null;
    bodyRef.current.value = null;
    articleURLRef.current.value = null;
    setSelectedApp('');
    setSelectedAppTopic('');
    dispatch(firebaseActions.cleanActiveDeviceCount(null));
  }, [dispatch]);

  useEffect(() => {
    dispatch(firebaseActions.handleGetTopics(null));
  }, [dispatch]);

  useEffect(() => {
    setSelectedAppAllTopicsEnabled(Boolean(topicSelectionData?.find(t => t.value === selectedAppTopic)?.allTopics));
  }, [topicSelectionData, selectedAppTopic]);

  useEffect(() => {
    if (prevSendingPushNotification && !sendingPushNotification) {
      setOpenDialog(false);
      handleClear();
    }
  }, [prevSendingPushNotification, sendingPushNotification, handleClear]);

  useEffect(() => {
    if (selectedApp && selectedAppTopic) {
      dispatch(
        firebaseActions.handleGetActiveDeviceCount({
          selectedApp,
          selectedAppTopic,
          allTopics: selectedAppAllTopicsEnabled,
        }),
      );
    }
  }, [dispatch, selectedApp, selectedAppTopic, selectedAppAllTopicsEnabled]);

  const handleClickSend = () => {
    if (titleRef && bodyRef && articleURLRef) {
      const title = titleRef.current?.value;
      const body = bodyRef.current?.value;
      const articleURL = articleURLRef.current?.value;
      if (title && body && articleURL && selectedAppTopic) {
        setFormError(false);
        setOpenDialog(true);
      } else {
        setFormError(true);
      }
    }
  };

  const handleSendThePushNotification = () => {
    const title = titleRef.current?.value;
    const body = bodyRef.current?.value;
    const articleURL = articleURLRef.current?.value;

    dispatch(
      firebaseActions.handleSendPushNotification({
        title,
        body,
        articleURL,
        selectedApp,
        selectedAppTopic,
        allTopics: selectedAppAllTopicsEnabled,
      }),
    );
  };

  const handleCloseConfirmation = () => {
    setOpenDialog(false);
  };

  const handleCloseError = () => {
    dispatch(firebaseActions.cleanPushNotificationError(null));
  };

  return (
    <>
      <div>
        <div className="notification-page-container">
          <Paper elevation={4} className="notification-page-form-container">
            <h2>Skapa ny pushnotis</h2>
            <div className="notification-page-form-field-wrapper">
              <InputField
                label="Rubrik på pushnotisen"
                helperText="valfritt att fylla i, max 30 tecken"
                inputRef={titleRef}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTitle(e.target.value)}
              />
            </div>
            <div className="notification-page-form-field-wrapper">
              <InputField
                label="Text i pushnotisen"
                helperText="obligatorisk att fylla i, max 196 tecken"
                inputRef={bodyRef}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBody(e.target.value)}
              />
            </div>
            <div className="notification-page-form-field-wrapper">
              <InputField label="Artikelns URL" helperText="obligatorisk att fylla i" inputRef={articleURLRef} />
            </div>
            <div className="notification-page-form-bottom-wrapper">
              <div className="notification-page-form-select-wrapper">
                <SelectField
                  label="Skicka pushen till följande app:"
                  data={appData}
                  onChnage={(value: string) => {
                    setSelectedApp(value);
                    setSelectedAppTopic('');
                    dispatch(firebaseActions.cleanActiveDeviceCount(null));
                  }}
                  seletedValue={selectedApp}
                />
              </div>
              {topicSelectionData ? (
                <div className="topic-wrapper">
                  <div className="topic-text">Vilka användare/pushnotis-prenumeranter ska få pushnotisen?</div>
                  <div className="topic-select-wrapper">
                    <SelectField
                      label="topic"
                      data={topicSelectionData}
                      onChnage={(value: string) => setSelectedAppTopic(value)}
                      seletedValue={selectedAppTopic}
                    />
                  </div>
                </div>
              ) : null}
            </div>
            {activeDeviceCount ? (
              <div className="notification-page-active-count">
                Selected app and topic online device count - {activeDeviceCount}
              </div>
            ) : null}
          </Paper>
          <Preview title={title} body={body} selectedApp={selectedApp} />
        </div>
        <div className="button-wrapper">
          <div className="cancel-button button" onClick={handleClear}>
            Clear
          </div>
          <div className="send-button button" onClick={handleClickSend}>
            Send
          </div>
        </div>
      </div>
      <Dialog
        open={openDialog}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{`Send Push Notification -  ${selectedApp}`}</DialogTitle>

        <DialogContent className="notification-dialog-content">
          {sendingPushNotification ? (
            <CircularProgress />
          ) : (
            <DialogContentText id="alert-dialog-description">
              {`You are going to send push notification to all users are using the "${selectedApp}" app`}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmation} variant="outlined" color="error">
            No
          </Button>
          <Button
            onClick={handleSendThePushNotification}
            autoFocus
            variant="contained"
            color="primary"
            disabled={sendingPushNotification}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={formError} autoHideDuration={2000} onClose={() => setFormError(false)}>
        <Alert severity="error">Please fill the all fields, to send the push notification</Alert>
      </Snackbar>
      <Snackbar open={sendingPushNotificationError ? true : false} autoHideDuration={2000} onClose={handleCloseError}>
        <Alert severity="error">Push Notification sending error</Alert>
      </Snackbar>
    </>
  );
};

export default NotificationPage;
