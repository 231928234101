import { call, put, takeLatest } from 'redux-saga/effects';

import { actions as authenticationActions } from 'app/store/slices/Authentication.slice';
import { handleLoginService } from 'app/api/Authentication.service';
import { LoginResponseType } from 'app/types';

function* watchHandleLogin(action: ReturnType<typeof authenticationActions.handleLogin>) {
  try {
    const response: LoginResponseType = yield call(handleLoginService, action.payload);
    yield put(authenticationActions.handleLoginSuccess(response));
  } catch (error) {
    yield put(authenticationActions.handleLoginFailure(error));
  }
}

const authenticationSaga = [takeLatest(authenticationActions.handleLogin, watchHandleLogin)];

export default authenticationSaga;
