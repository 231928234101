import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AuthenticationStateType, LoginActionType, LoginResponseType } from 'app/types';

const initialState: AuthenticationStateType = {
  loginData: null,
  logindataLoading: false,
  loginDataError: null,
};

const handleLogin = (state: AuthenticationStateType) => {
  state.logindataLoading = true;
  state.loginDataError = null;
};

const handleLoginSuccess = (state: AuthenticationStateType, action: PayloadAction<LoginResponseType>) => {
  state.loginData = action.payload;
  state.logindataLoading = false;
  state.loginDataError = null;
};

const handleLoginFailure = (state: AuthenticationStateType, action: PayloadAction<any>) => {
  state.loginData = null;
  state.logindataLoading = false;
  state.loginDataError = action.payload;
};

const cleanLogindata = (state: AuthenticationStateType, action: PayloadAction<any>) => {
  state.loginData = null;
  state.logindataLoading = false;
  state.loginDataError = null;
};

const authenticationSlice = createSlice({
  name: '@@authentication',
  initialState,
  reducers: {
    handleLogin(state, action: PayloadAction<LoginActionType>) {
      return handleLogin(state);
    },
    handleLoginSuccess(state, action: PayloadAction<LoginResponseType>) {
      return handleLoginSuccess(state, action);
    },
    handleLoginFailure(state, action: PayloadAction<any>) {
      return handleLoginFailure(state, action);
    },
    cleanLogindata(state, action: PayloadAction<null>) {
      return cleanLogindata(state, action);
    },
  },
});

export const { name, actions, reducer } = authenticationSlice;
