import React from 'react';
import { TextField } from '@mui/material';

import './InputFieldComponent.css';

const inputStyle = {
  '& .MuiFormLabel-root.Mui-focused': {
    color: '#000',
  },
  '& MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    'border-color': '#000',
  },
  '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#000',
  },
  '& .MuiOutlinedInput-root.Mui-focused  .MuiOutlinedInput-notchedOutline': {
    borderColor: '#000',
  },
};

const InputField: React.FC<any> = ({ label, helperText, inputRef, onChange }) => {
  return (
    <TextField
      label={label}
      variant="outlined"
      size="small"
      className="notification-page-form-input"
      sx={inputStyle}
      type="text"
      helperText={helperText}
      inputRef={inputRef}
      {...(onChange && { onChange: onChange })}
    />
  );
};

export default InputField;
