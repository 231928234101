import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { RootState } from 'app/store/Store';

const ProtectedRoute: React.FC<any> = ({ children }) => {
  const loginData = useSelector((state: RootState) => state.authentication.loginData);
  const loginDataError = useSelector((state: RootState) => state.authentication.loginDataError);

  if (!loginData || loginDataError || loginData.expirationTime < Math.floor(Date.now() / 1000)) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default ProtectedRoute;
