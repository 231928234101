import { store } from 'app/store/Store';
import axios from 'axios';

interface GetparmsType {
  [key: string]: string;
}

const getUserName = () => store.getState().authentication.loginData?.userName;

const POST: any = async (url: string, request: any) => {
  try {
    const { data } = await axios.post(url, request, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'User-Name': getUserName(),
      },
    });
    return data;
  } catch (error: any) {
    const e: any = new Error();
    e.response = error.response.data;
    throw e;
  }
};

const GET: any = async (url: string, params: GetparmsType) => {
  try {
    const { data } = await axios.get(url, {
      params,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return data;
  } catch (error: any) {
    const e: any = new Error();
    e.response = error.response.data;
    throw e;
  }
};

export { POST, GET };
