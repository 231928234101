import { configureStore, combineReducers } from '@reduxjs/toolkit';

import storage from 'redux-persist/es/storage';
import { persistReducer, persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import Reactotrone from '../config/Reactotrone.config';

import rootSaga from './Saga';
import { reducer as authenticationReducer } from './slices/Authentication.slice';
import { reducer as firebaseReducer } from './slices/Firebase.slice';

//initialize saga middleware
const sagaMiddleware = createSagaMiddleware();

//Push all the middlewares to below array
const middlewares = [sagaMiddleware];

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['authentication'],
};

const rootReducer = combineReducers({
  authentication: authenticationReducer,
  firebase: firebaseReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: middlewares,
  devTools: process.env.NODE_ENV !== 'production',
  // @ts-ignore3
  enhancers: [Reactotrone.createEnhancer()],
});

//run saga middleware
sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
