import { call, put, takeLatest } from 'redux-saga/effects';

import { actions as firebaseActions } from 'app/store/slices/Firebase.slice';
import { handleGetTopics, handleSendPushNotification, getActiveDeviceCount } from 'app/api/Firebase.service';
import { TopicResponseType, PushNotificationResponseType } from 'app/types';

function* watchHandleGetTopics(action: ReturnType<typeof firebaseActions.handleGetTopics>) {
  try {
    const response: TopicResponseType = yield call(handleGetTopics);
    yield put(firebaseActions.handleGetTopicsSuccess(response));
  } catch (error: any) {
    yield put(firebaseActions.handleGetTopicsFailure(error));
  }
}

function* watchHandleGetActiveDeviceCount(action: ReturnType<typeof firebaseActions.handleGetActiveDeviceCount>) {
  try {
    const { selectedApp, selectedAppTopic, allTopics } = action.payload;
    const response: number = yield call(getActiveDeviceCount, selectedApp, selectedAppTopic, allTopics);
    yield put(firebaseActions.handleGetActiveDeviceCountSuccess(response));
  } catch (error: any) {
    yield put(firebaseActions.handleGetActiveDeviceCountFailure(error));
  }
}

function* watchHandleSendPushNotification(action: ReturnType<typeof firebaseActions.handleSendPushNotification>) {
  try {
    const response: PushNotificationResponseType = yield call(handleSendPushNotification, action.payload);
    yield put(firebaseActions.handleSendPushNotificationSuccess(response));
  } catch (error: any) {
    yield put(firebaseActions.handleSendPushNotificationError(error));
  }
}

const firebaseSaga = [
  takeLatest(firebaseActions.handleGetTopics, watchHandleGetTopics),
  takeLatest(firebaseActions.handleGetActiveDeviceCount, watchHandleGetActiveDeviceCount),
  takeLatest(firebaseActions.handleSendPushNotification, watchHandleSendPushNotification),
];

export default firebaseSaga;
