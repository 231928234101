import React from 'react';
import './Header.css';

import LogoSVG from 'assets/Aller_media.svg';

const Header: React.FC<any> = () => {
  return (
    <div className="container">
      <img src={LogoSVG} className="header-image" alt="header-logo" />
    </div>
  );
};

export default Header;
