import { Paper } from '@mui/material';

import iphone from './iphone-canvas.jpg';
import hantLogotype from './hant-logotype.webp';
import svenskdamLogo from './svenskdamLogo.png';
import './styles.css';

interface PreviewProps {
  title: string;
  body: string;
  selectedApp: string;
}

const Preview = ({ title = '', body = '', selectedApp = '' }: PreviewProps) => {
  return (
    <Paper elevation={4} className="preview-container">
      <h2>Förhandsgranskning</h2>
      <img width={390} src={iphone} alt="Iphone canvas" />
      <div className="message">
        <img
          className="logotype"
          width={24}
          height={24}
          src={selectedApp.includes('svenskdam') ? svenskdamLogo : hantLogotype}
          alt="Hänt"
        />
        <div>
          <h4 className="title">{title}</h4>
          <p className="body">{body}</p>
        </div>
        <span className="now">now</span>
      </div>
    </Paper>
  );
};

export default Preview;
