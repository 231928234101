import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TextField, Paper } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import { actions as authenticateActions } from 'app/store/slices/Authentication.slice';
import { RootState } from 'app/store/Store';
import { usePrevious } from 'app/hooks/customHooks';

import './Login.page.css';

const inputStyle = {
  '& .MuiFormLabel-root.Mui-focused': {
    color: '#000',
  },
  '& MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    'border-color': '#000',
  },
  '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#000',
  },
  '& .MuiOutlinedInput-root.Mui-focused  .MuiOutlinedInput-notchedOutline': {
    borderColor: '#000',
  },
};

const LoginPage: React.FC<any> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const passwordRef: any = useRef(null);

  const loginData = useSelector((state: RootState) => state.authentication.loginData);
  const logindataLoading = useSelector((state: RootState) => state.authentication.logindataLoading);
  const prevLoginDataLoading = usePrevious(logindataLoading);
  const loginDataError = useSelector((state: RootState) => state.authentication.loginDataError);

  useEffect(() => {
    dispatch(authenticateActions.cleanLogindata(null));
  }, [dispatch]);

  useEffect(() => {
    if (prevLoginDataLoading && !logindataLoading && loginData && !loginDataError) {
      navigate('/');
    }
  }, [loginData, logindataLoading, navigate, prevLoginDataLoading, loginDataError]);

  const handleLogin = (e: any) => {
    const password = passwordRef?.current.value;
    if (password) {
      dispatch(
        authenticateActions.handleLogin({
          password,
          session_id: uuidv4(),
        }),
      );
    }
    e.preventDefault();
  };

  return (
    <div className="login-page-container">
      <Paper elevation={4} className="login-page-login-paper">
        <div className="login-page-login-form-header-text">Push Notification Service </div>
        <div className="login-page-login-form-header-helpertext">Please login from here</div>
        <form>
          <TextField
            id="login-form-password"
            label="Password"
            variant="outlined"
            size="small"
            className="login-page-login-form-input-password"
            sx={inputStyle}
            inputRef={passwordRef}
            type="password"
          />
          <div className="login-page-login-form-login-wrapper">
            <button className="login-page-login-button" onClick={handleLogin} type="submit">
              Login
            </button>
          </div>
        </form>
        {loginDataError ? (
          <div className="login-error-section">Login Error - Please check The Password And Connection</div>
        ) : null}
      </Paper>
    </div>
  );
};

export default LoginPage;
