import React from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import './SelectFieldComponent.css';

const SelectField: React.FC<any> = ({ label, data, onChnage, seletedValue }) => {
  const handleChnage = (e: SelectChangeEvent) => {
    onChnage(e.target.value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label" size="small">
        {label}
      </InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        label={label}
        onChange={handleChnage}
        size="small"
        value={seletedValue}>
        {data.map((item: any, index: number) => (
          <MenuItem value={item.value} key={`select-box-item-${index}`}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectField;
