import Reactotron, { trackGlobalErrors } from 'reactotron-react-js';
import { reactotronRedux } from 'reactotron-redux';

Reactotron.configure({}).use(trackGlobalErrors()).use(reactotronRedux()).connect();

const reactotrone = Reactotron.configure() // controls connection & communication settings
  .use(reactotronRedux())
  .connect(); // let's connect!

export default reactotrone;
